<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4" style="font-size: 16pt; font-weight: bold">IMPORTANT NOTE</p>

      <p class="mb-4" style="font-size: 11pt">
        Please print the In-Lab Exercise for Experiment 5 and attempt to answer the questions before
        lab. Bring your work along with either graph paper or a laptop containing graphing software
        so you can work on the Exercise in lab with your group members.
      </p>

      <p class="mb-5" style="font-size: 11pt; text-align: justify">
        To recognize that you have read this task and agree to follow the instructions above, please
        click the "I agree" button below and click the "Save Response" button.
      </p>

      <div v-for="option in options" :key="option.value">
        <v-checkbox
          v-model="inputs.input1"
          :disabled="!allowEditing"
          class="d-inline-block"
          :value="option.value"
        />
        <stemble-latex :content="option.expression" />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UOttawaNewPrelabQ5',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {
          expression: 'I agree',
          value: 'agreed',
        },
      ],
    };
  },
};
</script>
